export { wrapRootElement } from "./src/common/apollo/wrap-root-element";


export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
  }) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/property\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/for-rent/)) {
      return false;
    }
  
    if (location.pathname.match(/property-map\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property-map\/for-rent/)) {
      return false;
    }
  
    return true;
  };
  