import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./client"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
// import { ContainerContextProvider } from "../store/ContainerContext";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    {/* <ContainerContextProvider> */}
    <Elements stripe={stripePromise}>{element}</Elements>
    {/* </ContainerContextProvider> */}
  </ApolloProvider>
)
